<template>
  <div
    v-if="Object.keys(item).length"
    class="rselectitem"
    :class="{ checkboxes, disabled }"
    @click="onClickRow"
  >
    <div
      ref="selectItem"
      class="rselectitem--container"
    >
      <div
        v-if="hasCustomItemSlots || checkboxes"
        class="d-flex align-center py-2"
        style="max-width: calc(100% - 45px);"
      >
        <div
          v-if="checkboxes"
          class="rselectitem--checkbox"
        >
          <v-checkbox
            ref="checkbox"
            v-model="isSelected"
            :disabled="disabled"
            color="#239EDB"
            dense
            @change="onChange"
          />
        </div>
        <div
          v-if="hasCustomItemSlots"
          class="rselectitem--customitem text-truncate"
        >
          <slot
            name="item"
            :item="item"
          />
        </div>
      </div>

      <div
        v-if="!hasCustomItemSlots"
        class="rselectitem--row py-2"
      >
        <span class="px-2 py-0 rselectitem--label">
          {{ item.label }}
        </span>
        <span
          v-if="item.appendIcon"
          class="px-4 py-0 rselectitem--appendicon"
        >
          <v-icon dense>
            {{ item.appendIcon }}
          </v-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import didNotClickOn from '@/utils/didNotClickOn'

export default {
  name: 'RSelectItem',
  props: {
    checkboxes: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    item: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      appendIcon: {
        type: String,
        required: false,
      },
      color: {
        type: String,
        required: false,
      },
    },
  },
  data() {
    return {
      isSelected: this.$props.item.selected,
    }
  },
  computed: {
    hasCustomItemSlots() {
      return !!this.$scopedSlots.item
    },
  },
  watch: {
    item: {
      handler({ selected }) {
        this.$data.isSelected = selected
      },
      deep: true,
    },
  },
  methods: {
    onClickRow(e) {
      if (!this.$props.checkboxes || didNotClickOn(e.target, { el: this.$refs.checkbox.$el })) {
        if (didNotClickOn(e.target, { classes: ['rselectitem--appendicon'] })) {
          this.$data.isSelected = !this.$data.isSelected
          if (this.$data.isSelected) {
            this.$emit('selected')
          } else {
            this.$emit('deselected')
          }
        } else {
          this.$emit('click:append-icon', this.$props.item)
        }
      }
    },
    onChange(data) {
      if (data) {
        this.$emit('selected')
      } else {
        this.$emit('deselected')
      }
    },
  },
}
</script>

<style scoped>
.rselectitem--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.rselectitem .rselectitem--label {
  cursor: pointer;
  color: var(--r-text-color);
}
.rselectitem--row {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.rselectitem--container:hover {
  background: var(--r-light-grey);
  cursor: pointer;
}
.rselectitem.disabled {
  pointer-events: none;
}
/deep/ .v-input--checkbox.v-input--dense {
  margin-top: 0;
  padding-top: 0;
}
/deep/ .v-input--checkbox .v-messages {
  display: none;
}
.rselectitem.checkboxes .rselectitem--label {
  margin-top: 2px;
}
.rselectitem--appendicon {
  max-width: 37px;
  width: 37px;
}
.rselectitem--checkbox {
  margin-right: 8px;
}
/deep/ .rselectitem--checkbox i:before {
  font-size: 16px;
}
</style>
