var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rselect"
  }, [_vm.multiple ? _c('v-col', {
    staticClass: "pa-0"
  }, [[_vm._t("label")], _c('r-multiple-select', {
    ref: "selectcomponent",
    attrs: {
      "placeholder": _vm.placeholder,
      "search-place-holder": _vm.searchPlaceholder,
      "items": _vm.selectItems,
      "loading": _vm.loading,
      "disabled": _vm.disabled,
      "show-search": _vm.showSearch,
      "prepend-items": _vm.prependItems || [],
      "append-items": _vm.appendItems || [],
      "always-open": _vm.alwaysOpen,
      "selected-records-count": _vm.selectedRecordsCount,
      "excluded-records-count": _vm.excludedRecordsCount,
      "show-select-all": _vm.showSelectAll,
      "total-records": _vm.totalRecords,
      "selected-items-for-chip": _vm.selectedItemsForChip
    },
    on: {
      "change": function (data) {
        return _vm.$emit('change', data);
      },
      "on:singleAction": function (_ref) {
        var selectedItem = _ref.selectedItem,
          selected = _ref.selected;
        return _vm.onSingleItemChange({
          selectedItem,
          selected
        });
      },
      "click:prepend-item": function (data) {
        return _vm.$emit('click:prepend-item', data);
      },
      "click:append-item": function (data) {
        return _vm.$emit('click:append-item', data);
      },
      "click:append-icon": function (data) {
        return _vm.$emit('click:append-icon', data);
      },
      "change:search": _vm.onSearchChange,
      "on:popup-visibility-change": _vm.toggleScrollEvents,
      "on:select-all": function (_ref2) {
        var isSelectedAll = _ref2.isSelectedAll;
        return _vm.$emit('on:select-all', isSelectedAll);
      }
    },
    scopedSlots: _vm._u([_vm.hasCustomFiltersSlot ? {
      key: "filters",
      fn: function () {
        return [_vm._t("filters")];
      },
      proxy: true
    } : null, _vm.hasCustomItemSlots ? {
      key: "item",
      fn: function (slotProps) {
        return [_vm._t("item", null, {
          "item": slotProps.item
        })];
      }
    } : null, _vm.hasCustomPrependItemSlot ? {
      key: "prepend-item",
      fn: function () {
        return [_vm._t("prepend-item")];
      },
      proxy: true
    } : null], null, true)
  })], 2) : _c('v-col', {
    staticClass: "pa-0"
  }, [_vm._t("label"), _c('r-single-select', {
    ref: "selectcomponent",
    attrs: {
      "placeholder": _vm.placeholder,
      "search-placeholder": _vm.searchPlaceholder,
      "items": _vm.selectItems,
      "loading": _vm.loading,
      "disabled": _vm.disabled,
      "show-search": _vm.showSearch,
      "allow-empty": _vm.allowEmpty,
      "prepend-items": _vm.prependItems || [],
      "append-items": _vm.appendItems || []
    },
    on: {
      "change": function (data) {
        return _vm.$emit('change', data);
      },
      "click:prepend-item": function (data) {
        return _vm.$emit('click:prepend-item', data);
      },
      "click:append-item": function (data) {
        return _vm.$emit('click:append-item', data);
      },
      "click:append-icon": function (data) {
        return _vm.$emit('click:append-icon', data);
      },
      "change:search": _vm.onSearchChange,
      "on:popup-visibility-change": _vm.toggleScrollEvents
    },
    scopedSlots: _vm._u([_vm.hasCustomPrependItemSlot ? {
      key: "prepend-item",
      fn: function () {
        return [_vm._t("prepend-item")];
      },
      proxy: true
    } : null], null, true)
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }