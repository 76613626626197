var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "rsingleselect pa-0"
  }, [!_vm.alwaysOpen ? _c('v-row', {
    staticClass: "selector-trigger ma-0",
    class: {
      disabled: _vm.disabled
    },
    on: {
      "click": _vm.onOpenPopup
    }
  }, [_vm.selectedItem ? _c('v-col', {
    staticClass: "py-1 px-2"
  }, [_vm._v(" " + _vm._s(_vm.triggerLabel) + " ")]) : _c('v-col', {
    staticClass: "py-1 px-2"
  }, [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]), _vm.selectedItem && _vm.selectedItem.appendIcon ? _c('v-col', {
    staticClass: "py-1 px-2 rsingleselect--appendicon",
    staticStyle: {
      "max-width": "32px"
    }
  }, [_c('v-icon', {
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedItem.appendIcon) + " ")])], 1) : _vm._e(), _c('v-col', {
    staticClass: "py-1 pr-2",
    staticStyle: {
      "max-width": "24px"
    }
  }, [_vm.loading ? _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-progress-circular', {
    staticClass: "loading-icon",
    attrs: {
      "indeterminate": "",
      "color": "#BDBDBD",
      "width": "2",
      "size": "20"
    }
  })], 1) : _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-icon', {
    staticClass: "expand-icon mt-1"
  }, [_vm._v(" expand_more ")])], 1)], 1)], 1) : _vm._e(), _vm.displayPopup ? _c('v-row', {
    staticClass: "selector-popup ma-0"
  }, [_c('v-card', {
    staticClass: "elevation-0"
  }, [_vm.showSearch ? _c('v-col', {
    staticClass: "pa-0"
  }, [_c('RSearchInput', {
    attrs: {
      "placeholder": "Search",
      "value": _vm.query
    },
    on: {
      "change": _vm.onInputChange
    }
  })], 1) : _vm._e(), _vm._t("prepend-item"), _c('v-col', {
    ref: "itemsWrapper",
    staticClass: "pa-0 selector-popup--items"
  }, [_vm._l(_vm.prependItems, function (prependItem) {
    return _c('RSelectItem', {
      key: prependItem.value,
      staticClass: "rsingleselect--prependitem",
      attrs: {
        "item": prependItem
      },
      on: {
        "selected": function ($event) {
          return _vm.onClickPrependItem(prependItem);
        },
        "click:append-icon": function ($event) {
          return _vm.onClickAppendIcon(prependItem);
        }
      }
    });
  }), _vm._l(_vm.selectItems, function (item) {
    var _item$key;
    return _c('RSelectItem', {
      key: (_item$key = item.key) !== null && _item$key !== void 0 ? _item$key : item.value,
      staticClass: "rsingleselect--item",
      class: {
        borderBottom: _vm.hasBorderBottom
      },
      attrs: {
        "item": item
      },
      on: {
        "selected": function ($event) {
          return _vm.toggleSelection(item);
        },
        "click:append-icon": function ($event) {
          return _vm.onClickAppendIcon(item);
        }
      }
    });
  }), _vm._l(_vm.appendItems, function (appendItem) {
    return _c('RSelectItem', {
      key: appendItem.value,
      staticClass: "rsingleselect--appenditem",
      attrs: {
        "item": appendItem
      },
      on: {
        "selected": function ($event) {
          return _vm.onClickAppendItem(appendItem);
        },
        "click:append-icon": function ($event) {
          return _vm.onClickAppendIcon(appendItem);
        }
      }
    });
  })], 2)], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }