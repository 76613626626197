<template>
  <v-col class="rsingleselect pa-0">
    <v-row
      v-if="!alwaysOpen"
      class="selector-trigger ma-0"
      :class="{ disabled }"
      @click="onOpenPopup"
    >
      <v-col
        v-if="selectedItem"
        class="py-1 px-2"
      >
        {{ triggerLabel }}
      </v-col>

      <v-col
        v-else
        class="py-1 px-2"
      >
        {{ placeholder }}
      </v-col>
      <v-col
        v-if="selectedItem && selectedItem.appendIcon"
        class="py-1 px-2 rsingleselect--appendicon"
        style="max-width:32px"
      >
        <v-icon dense>
          {{ selectedItem.appendIcon }}
        </v-icon>
      </v-col>
      <v-col
        class="py-1 pr-2"
        style="max-width:24px"
      >
        <v-row
          v-if="loading"
          class="ma-0"
          justify="end"
        >
          <v-progress-circular
            class="loading-icon"
            indeterminate
            color="#BDBDBD"
            width="2"
            size="20"
          />
        </v-row>
        <v-row
          v-else
          class="ma-0"
          justify="end"
        >
          <v-icon class="expand-icon mt-1">
            expand_more
          </v-icon>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="displayPopup"
      class="selector-popup ma-0"
    >
      <v-card class="elevation-0">
        <v-col
          v-if="showSearch"
          class="pa-0"
        >
          <RSearchInput
            placeholder="Search"
            :value="query"
            @change="onInputChange"
          />
        </v-col>
        <slot name="prepend-item" />
        <v-col
          ref="itemsWrapper"
          class="pa-0 selector-popup--items"
        >
          <RSelectItem
            v-for="prependItem in prependItems"
            :key="prependItem.value"
            class="rsingleselect--prependitem"
            :item="prependItem"
            @selected="onClickPrependItem(prependItem)"
            @click:append-icon="onClickAppendIcon(prependItem)"
          />
          <RSelectItem
            v-for="item in selectItems"
            :key="item.key ?? item.value"
            class="rsingleselect--item"
            :class="{borderBottom: hasBorderBottom}"
            :item="item"
            @selected="toggleSelection(item)"
            @click:append-icon="onClickAppendIcon(item)"
          />
          <RSelectItem
            v-for="appendItem in appendItems"
            :key="appendItem.value"
            class="rsingleselect--appenditem"
            :item="appendItem"
            @selected="onClickAppendItem(appendItem)"
            @click:append-icon="onClickAppendIcon(appendItem)"
          />
        </v-col>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
import didNotClickOn from '@/utils/didNotClickOn'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RSelectItem from '@/components/library/atoms/RSelectItem'

export default {
  name: 'RSingleSelect',
  components: {
    RSearchInput,
    RSelectItem,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    prependItems: Array,
    appendItems: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    hasBorderBottom: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showPopup: false,
      query: '',
      selectItems: [...this.$props.items],
    }
  },
  computed: {
    selectedItem() {
      return this.selectItems.find((item) => item.selected)
    },
    triggerLabel() {
      if (this.selectedItem && this.selectedItem.label) {
        return this.selectedItem.label
      }
      return this.placeholder
    },
    displayPopup() {
      const shouldShowPopup = this.showPopup || this.alwaysOpen
      this.$emit('on:popup-visibility-change', { shouldShowPopup })
      return shouldShowPopup
    },
  },
  watch: {
    items() {
      this.$data.selectItems = [...this.$props.items]
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.onClickPage)

    const selectedItems = this.items.filter((item) => item.selected)
    const initialValueIsRequired = !this.allowEmpty && !selectedItems.length
    if (initialValueIsRequired) {
      // eslint-disable-next-line no-console
      console.error('One selected item is required, since allowEmpty is "false"')
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.onClickPage)
  },
  methods: {
    onInputChange(eventData) {
      const { value } = eventData
      this.query = value.trim()
      this.$emit('change:search', { value })
    },
    toggleSelection(newSelectedItem) {
      let updatedItem

      this.$data.selectItems = this.$data.selectItems.map((item) => {
        if (item === newSelectedItem) {
          const isItemAlreadySelected = item.selected
          if (isItemAlreadySelected && !this.allowEmpty) {
            updatedItem = item
            return item
          }

          updatedItem = {
            ...item,
            selected: !item.selected,
          }

          return updatedItem
        }
        if (item.selected) {
          return {
            ...item,
            selected: false,
          }
        }

        return item
      })

      if (updatedItem.selected) {
        this.$emit('change', updatedItem)
      } else {
        this.$emit('change', null)
      }
      this.$data.showPopup = false
    },
    onClickPrependItem(item) {
      this.$emit('click:prepend-item', item)
    },
    onClickAppendItem(item) {
      this.$emit('click:append-item', item)
    },
    onClickAppendIcon(item) {
      this.$emit('click:append-icon', item)
    },
    onOpenPopup(e) {
      if (this.disabled) {
        return
      }
      if (didNotClickOn(e.target, { classes: ['rsingleselect--appendicon'] })) {
        this.$data.showPopup = !this.$data.showPopup
        return
      }
      this.$emit('click:append-icon', this.selectedItem)
    },
    onClickPage(event) {
      if (didNotClickOn(event.target, { el: this.$el })) {
        this.showPopup = false
      }
    },
  },
}
</script>

<style scoped>
.selector-trigger {
 cursor: pointer;
 border-radius: 3px;
}
.rsingleselect--item {
  width: 100%;
}
.borderBottom {
  border-bottom: 1px solid var(--r-border-color) !important;
}
.selector-trigger.disabled {
  background: var(--light-grey-color);
}
.selector-trigger {
  border: 1px solid var(--light-grey-color);
  user-select: none;
}
.selector-trigger:not(.disabled):hover,
.selector-trigger:not(.disabled).active {
  border-color: var(--primary-color)
}
.selector-popup {
  position: relative;
}
.selector-popup > .v-card {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>
